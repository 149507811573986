<template>
  <div :class="`Loyalty-banner--${variant}`" class="Loyalty-banner">
    <ux-atoms-icon :name="logo" class="Loyalty-banner__header-logo" size="m" />
    <div :class="`Loyalty-banner__content--${variant}`" class="Loyalty-banner__content">
      <div :class="`Loyalty-banner__content-header--${variant}`" class="Loyalty-banner__content-header">
        <h2 :class="`Loyalty-banner__content-header-title--${variant}`" class="Loyalty-banner__content-header-title">{{ loyaltyBanner.title }}</h2>
      </div>
      <div class="Loyalty-banner__content-info">
        <ul class="Loyalty-banner__content-listing" v-if="usp.length > 0 && variant === LoyaltyVariant.Home">
          <li :key="`usp-home-${item}`" class="Loyalty-banner__content-listing-item" v-for="item in usp">
            <ux-molecules-icon-label :label="item" icon="listing" variant="loyalty" />
          </li>
        </ul>
        <div class="Loyalty-banner__content-usp-text" v-if="usp.length > 0 && variant === LoyaltyVariant.Rdp">
          <ux-atoms-typo :key="`usp-rdp-${item}`" :text="item" as="p" v-for="item in usp" variant="text-regular" />
        </div>

        <div
          :class="`Loyalty-banner__content-cta--${variant}`"
          class="Loyalty-banner__content-cta"
          v-if="displayCta && loyaltyBanner.ctaLabel && loyaltyBanner.ctaUrl"
        >
          <ux-atoms-link :target="isExternal(loyaltyBanner.ctaUrl) ? '_blank' : '_self'" :to="loyaltyBanner.ctaUrl" @click="gtmLoyaltyBannerEvent">
            <ux-atoms-button :aria-label="loyaltyBanner.ctaLabel" :label="loyaltyBanner.ctaLabel" variant="primary" />
          </ux-atoms-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LoyaltyVariant } from '~/core/types';
import { LoyaltyBanner } from '~/graphql';
import { gtmEvent, gtmPageName } from '~/helpers/gtm';

enum LoyaltyLogo {
  AccorPlus = 'logo-accorplus',
  All = 'logo-all'
}

interface Props {
  displayCta?: boolean;
  isAccorPlus?: boolean;
  loyaltyBanner: LoyaltyBanner;
  variant: LoyaltyVariant;
}

const props = defineProps<Props>();

const config = useRuntimeConfig();
const route = useRoute();

const usp = computed(() => props.loyaltyBanner.usp ?? []);

const logo = computed(() => (props.isAccorPlus ? LoyaltyLogo.AccorPlus : LoyaltyLogo.All));

const isExternal = (url: string) => {
  return !url.startsWith(config.public.baseUrl);
};

const gtmLoyaltyBannerEvent = () => {
  gtmEvent('bloc_interact', {
    bloc_interaction: 'become a member',
    bloc_name: 'loyalty banner',
    pagename: gtmPageName(route.name as string)
  });
};
</script>

<style lang="scss" scoped>
@use 'LoyaltyBanner.scss';
</style>
